<template>
  <b-card v-if="detail" body-class="pt-0">
    <template #header>
      <h4 class="card-title">
        <strong>
          {{ detail.title }}
        </strong>
        -
        <b-badge :variant="detail.is_supplying? 'success' : 'danger'">
          {{ detail.is_supplying ? 'Enabled' : 'Disabled' }}
        </b-badge>
        <b-badge v-if="detail.deleted_at" variant="danger">
          Deleted
        </b-badge>
        <b-button variant="light" @click="downloadFile" class="text-danger btn-outline-primary px-2 py-0 ml-2"
                  style="font-size: 1em" title="Download file">
          <i class="material-icons">cloud_download</i>
        </b-button>
      </h4>
    </template>
    <b-row>
      <b-col>
        <b-table small hover bordered :items="[detail.vehicle]" :fields="['make','model','engine','generation']"/>
        <b-table small hover bordered :items="[detail.prices]" :fields="fields"/>
        <h4>Project info</h4>
        <b-row>
          <b-col>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between">
                <span>Tune type</span><span>{{ detail.file_type }}</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between">
                <span>DSG family</span><span>{{ detail.dsg_family }}</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between">
                <span>flashtool</span><span>{{ detail.flashtool }}</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between">
                <span>flashtool protocol</span><span>{{
                  detail.flashtool_protocol ? detail.flashtool_protocol : '--'
                }}</span>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between">
                <span>P/N</span><span>{{ detail.hardware_number }}</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between">
                <span>Software version</span><span>{{ detail.software_version }}</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between">
                <span>Created at</span><span>{{ detail.created_at|localeDate }}</span>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row class="my-3 py-2"
               v-if="(!detail.stock_linked_file && !detail.client_instructions && !detail.deleled_at)">
          <b-col>
            <h5>Add tuned file.</h5>
            <form-tuned-file :dataToCompare="this.dataToCompare" :stock_file="detail" @save="$refs.list.load(); "/>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="6">
        <tuned-file-list ref="list" :stock_file_id="detail._id" @dataToCompare="dataToCompareWithFormTunedFile($event)" @update_has_tuned_files="update_has_tuned_files($event)" @loaded=""
                         v-if="(!detail.stock_linked_file && !detail.client_instructions)"/>

        <link-to-stock
          v-if="!detail.has_tuned_files && (!detail.stock_linked_file && !detail.client_instructions)"
          :stock_file="detail" @setStockFile="fetchFile"/>

        <router-link :to="{name: 'project-tuned-file', params: {id: detail.stock_linked_file._id}}"
                     v-if="detail.stock_linked_file">
          <h5>Project Linked</h5>
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between">
              <span>Title</span><span>{{ detail.stock_linked_file.title }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span>P/N</span><span>{{ detail.stock_linked_file.hardware_number }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span>Software version</span><span>{{ detail.stock_linked_file.software_version }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span>Created at</span><span>{{ detail.stock_linked_file.created_at|localeDate }}</span>
            </b-list-group-item>
          </b-list-group>

        </router-link>

        <div v-html="detail.client_instructions" class="p-3 external_link"></div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {get, post} from "@/services/api";
import OptionsEditor from "@/components/SoftwareOrders/OptionsEditor";
import TunedFileList from "@/views/dashboard/file-server/TunedFileList";
import FormTunedFile from "@/views/dashboard/file-server/FormTunedFile";
import LinkToStock from "@/views/dashboard/file-server/stock-file/LinkToStock";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "StockFileDetail",
  components: {LinkToStock, FormTunedFile, TunedFileList, OptionsEditor},
  mixins: [show_alert_mixin],
  data() {
    return {
      dataToCompare:null,
      model: {
        title: null,
        description: null,
        tuned_file: null,
        stage: null,
        options: [],
        visual_options: [],
        status: false,
        recommended: false,
        is_hpp: false
      },
      detail: null,
      stages: {
        ECU: [
          {text: 'Stage 1', value: '1'},
          {text: 'Stage 2', value: '2'},
          {text: 'Stage 2+', value: '2.1'},
          {text: 'Stage 3', value: '3'},
          {text: 'Stage 4', value: '4'}
        ],
        TCU: [
          {text: 'Drivability', value: 'Stock'},
          {text: 'Stage 1', value: '1'},
          {text: 'Stage 2', value: '2'},
          {text: 'Stage 2+', value: '2.1'},
          {text: 'Stage 3', value: '3'},
          {text: 'Stage 4', value: '4'}
        ],
      },
      prices: {
        'stockPrice': 'Drivability',
        'stageOnePrice': 'Stage 1',
        'stageTwoPrice': 'Stage 2',
        'stageTwoPlusPrice': 'Stage 2+',
        'stageThreePrice': 'Stage 3',
        'stageFourPrice': 'Stage 4',
      },
      fields: [],
    };
  },
  computed: {
    showIsHPP() {
      return (this.detail.file_type === 'TCU' && ['2.1', '3', '4'].includes(this.model.stage));
    },
  },
  methods: {
    dataToCompareWithFormTunedFile(data){
     this.dataToCompare = data
    },

    update_has_tuned_files(event){
      this.detail.has_tuned_files=event
    },

    async fetchFile() {
      const {id} = this.$route.params;
      const {data} = await get(`files/stock/${id}`, null, true);
      this.detail = data;
      this.fields = Object.keys(data.prices).map(key => {
        return {
          label: this.prices[key],
          key,
        };
      });
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          const formData = new FormData()
          Object.keys(this.model).forEach(key => {
            formData.append(key, Array.isArray(this.model[key]) ? JSON.stringify(this.model[key]) : this.model[key])
          })
          this.$store.commit('loaderManager', true);
          post(`files/upload-tuned-file/${this.detail._id}`, formData, true)
            .then(() => {
              this.showAlertNotification("Files uploaded successfully", "success");
              this.$refs.list.load();
            }).finally(() => this.$store.commit('loaderManager', false))
        }
      })
    },
    getState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    clearModel() {
      this.model = {
        title: null,
        description: null,
        tuned_file: null,
        stage: null,
        options: [],
        visual_options: [],
        status: false,
        recommended: false,
        is_hpp: false
      };
      this.$refs.editor.reset()
    },
    paginate(page) {
      this.pagination.currentPage = page;
      this.getTunedFiles();
    },
    setOptions(data) {
      this.model.options = data.map(option => {
        if (Array.isArray(option.options) && option.options.length) {
          const result = {}
          result[option.option_id] = option.options[0].id;
          return result
        }
        return option.option_id;
      });
      this.model.visual_options = data.map(option => ({
        id: option.option_id,
        name: option.name,
        description: option.description,
        location: option.location,
        value: Array.isArray(option.options) && option.options.length ? option.options[0].description : true,
        pre_included: option.required
      }))
    },
    downloadFile() {
      this.$store.commit('loaderManager', true);
      get(`files/download-stock-file/${this.detail._id}`, null, true, 'blob')
        .then(({data}) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link
            .setAttribute('href', url);
          link
            .setAttribute('download', this.detail.title);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => this.showAlertNotification("Something went wrong", "danger"))
        .finally(() => this.$store.commit('loaderManager', false));
    },

  },
  created() {
    this.fetchFile();
  },
  watch: {
    'model.stage': async function () {
      this.$refs.editor.reset();
      await this.$refs.editor.loadVehicle(this.detail.vehicle_id);
      const params = {
        type: this.detail.file_type.replace('TCU', 'DSG'),
        id: this.model.stage
      };
      this.$refs.editor.setParams(params);
    },
    '$route.params.id': function () {
      this.$store.commit('loaderManager', true);
      this.fetchFile().finally(() => this.$store.commit('loaderManager', false));
    }
  }
}
</script>

<style scoped>

</style>
