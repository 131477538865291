<template>
  <div>
    <div class="text-right">
      <div class="d-flex justify-content-end form-group">
        <b-form-input v-model="query" @keyup="keySearch" placeholder="Search" class="w-auto"/>
        <b-button @click="viewTrash" variant="dark">
          <i class="fas fa-trash" v-if="!deleted"></i>
          <i class="fas fa-undo" v-else></i>
          {{ deleted ? 'Back' : 'View Trash' }}
        </b-button>
      </div>
    </div>
    <div class="table-responsive">
      <b-table :fields="fields" :items="pagination.tableData" bordered class="custom-table" no-local-sorting
               :sort-by.sync="sort.filed" :sort-desc.sync="sort.desc">
        <template #cell(stage)="{item}">{{ item.stage.replace('Stock', 'Drivability').replace('.1', '+') }}</template>
        <template #cell(status)="{item}">
          <b-badge :variant="item.status?'success':'danger'">
            {{ item.status ? 'Enabled' : 'Disabled' }}
          </b-badge>
        </template>
        <template #cell(created_at)="{item}">
          {{ item.created_at|localeDate }}
        </template>
        <template #cell(is_hpp)="{item}">
          {{ item.is_hpp ? 'Yes' : 'No' }}
        </template>
        <template #cell(actions)="{item}">
          <b-button-group>
            <router-link class="py-1" :to="{name: 'view-tuned-file', params: {id: item._id}}" v-slot="{navigate}">
              <b-button @click="navigate" @keypress.enter="navigate" role="link" variant="dark" size="sm">
                <i class="fas fa-eye"></i>
              </b-button>
            </router-link>
            <b-button :disabled="item.deleted_at" @click="changeStatus(item)"
                      :variant="[item.is_supplying? 'success':'danger']"
                      class="py-1">
              <i class="fas" :class="[item.is_supplying? 'fa-check':'fa-times']"></i>
            </b-button>
            <b-button class="py-1" v-if="!item.deleted_at" @click="trashFile(item)" variant="danger" size="sm">
              <i class="fas fa-trash"></i>
            </b-button>
            <b-button class="py-1" v-else @click="recoverFile(item)" variant="success" size="sm" title="Restore file">
              <i class="fas fa-trash-restore"></i>
            </b-button>
            <b-button class="py-1" @click="deleteFile(item)" variant="danger" size="sm" title="Delete file permanently">
              <i class="fas fa-eraser"></i>
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </div>
    <b-pagination v-model="pagination.currentPage" :total-rows="pagination.total" :per-page="pagination.perPage"
                  align="end" class="my-0" @change="paginate"/>
  </div>
</template>

<script>
import {deleted, get, post} from "@/services/api";
import {Pagination} from "@/utils/pagination";

export default {
  name: "TunedFileList",
  props: {
    stock_file_id: String,
  },
  data: () => ({
    pagination: new Pagination(),
    sort: {
      filed: 'stage',
      desc: null,
    },
    query: '',
    deleted: false,
    fields: [
      {key: 'title', label: 'Title', sortable: true},
      {key: 'stage', label: 'stage', sortable: true},
      {key: 'options.length', label: 'N° options', sortable: true},
      {key: 'created_at', 'label': 'Created at', sortable: true},
      {key: 'is_hpp', label: 'Is HHP', sortable: true},
      {key: 'status', label: 'Status', sortable: true},
      'Actions',
    ],
    timer: null,
  }),
  methods: {
    paginate(page) {
      this.pagination.currentPage = page;
      this.load();
    },
    load() {
      get(`files/tuned/${this.stock_file_id}?page=${this.pagination.currentPage}&limit=${this.pagination.perPage}&sort=${this.sort.filed}:${this.sort.desc ? 'desc' : 'asc'}&q=${this.query}&deleted=${this.deleted}`)
        .then(({data}) => {
          this.pagination.total = data.count;
          this.pagination.setTableData(data.data)
          this.$emit('loaded', data.count > 0);
          this.dataInsideFilesToCompare()
        })
    },
    keySearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.pagination.currentPage = 1;
        this.load()
      }, 800);
    },
    viewTrash() {
      this.pagination.currentPage = 1;
      this.deleted = !this.deleted;
      this.load();
    },
    trashFile(item) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure you want to move this file to Trash?',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          if(this.pagination.tableData.length == 1){
            this.$emit('update_has_tuned_files', false)
          }
          post(`files/tuned/${item._id}`, null, true).then(() => {
            this.load();
          })
        }
      })
    },
    deleteFile(item) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure? This action cannot be undone',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
           if(this.pagination.tableData.length == 1){
             this.$emit('update_has_tuned_files', false)
           }
          deleted(`files/tuned/${item._id}`, true).then(() => {
            this.load();
          })
        }
      })
    },
    recoverFile(item) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure you want to put it back?',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          this.$emit('update_has_tuned_files', true)
          post(`files/tuned-recover/${item._id}`, null, true).then(() => {
            this.load();
          })
        }
      })
    },
    changeStatus(item) {
      post(`files/change-tuned-status/${item._id}`, {status: !item.status})
        .then(() => {
          this.load();
        })
    },

    dataInsideFilesToCompare(){
      let dataToCompare = this.pagination.tableData.map(function(el){
      return {
        title:el.title,
        option: el.options,
        stage:el.stage,
        hp:el.hp,
        nm:el.nm
      }})
      this.$emit('dataToCompare', dataToCompare);
    },

  },
  mounted() {
    this.load();
  },
  watch: {
    sort: {
      handler() {
        this.load();
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
