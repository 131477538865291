<template>
  <b-card title="Link to other project/ add instructions to customer">
    <b-form-group>
      <label>What do you prefer?</label>
      <b-form-radio-group
        size="lg"
        :options="[{value: true, text: 'Give instructions to client'},{value: false, text: 'Link other project'}]"
        v-model="isChecked"/>
    </b-form-group>
    <div v-if="isChecked">
      <ckeditor v-model="value" :config="editorConfig"></ckeditor>
    </div>
    <div v-else>
      <label>Find project file</label>
      <vue-autosuggest
        :get-suggestion-value="getSuggestionValue"
        :suggestions="[{data: stock_items}]"
        :input-props="{placeholder: 'Search project ID', class: 'form-control'}"
        @input="keySearch"
        @selected="selectStock"
      >
        <template slot-scope="{suggestion}">
          {{ suggestion.item.file_type }} - {{ suggestion.item.title }}
          {{ suggestion.item.hardware_number }}/{{ suggestion.item.software_version }}
        </template>
      </vue-autosuggest>
    </div>
    <div class="form-group pt-3 text-right">
      <b-button @click="save" size="lg" variant="primary">Save</b-button>
    </div>
  </b-card>
</template>

<script>
import {VueAutosuggest} from "vue-autosuggest";
import {apiUrl, get, post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "LinkToStock",
  mixins: [show_alert_mixin],
  components: {
    VueAutosuggest
  },
  props: {
    stock_file: Object,
  },
  data: () => ({
    isChecked: false,
    inputValue: '',
    stock_items: [],
    timer: null,
    editorConfig: {
      extraPlugins: 'uploadimage', // 'filebrowser',
      // filebrowserBrowseUrl: `${apiUrl}news/upload-image`,
      filebrowserUploadUrl: `${apiUrl}news/upload-image`
    },
    value: null,
  }),
  methods: {
    loadStockFiles(query) {
      this.loading = true;
      get(`files/link-stock-filter?q=${query ? query : ''}&file_type=${this.stock_file.file_type}&flash_tool=${this.stock_file.flashtool}`, null, true)
        .then(({data}) => {
          this.stock_items = data.filter(item => item._id !== this.stock_file._id);
          this.loading = false
        })
    },
    keySearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadStockFiles(e)
      }, 800);
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.title;
    },
    selectStock(suggestion) {
      this.value = suggestion.item._id;
    },
    async save() {
      if (!this.value)
        return
      const {isConfirmed} = await this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes!'
      })
      if (isConfirmed) {
        post(`files/stock-extra-option`, {
          stock_file_id: this.stock_file._id,
          value: this.value,
          field: this.isChecked ? 'client_instructions' : 'stock_linked_file'
        }, true)
          .then(({data}) => {
            this.$emit('setStockFile', data);
            this.showAlertNotification("Saved")
          })
      }

    }
  }
}
</script>
