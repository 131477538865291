var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right"},[_c('div',{staticClass:"d-flex justify-content-end form-group"},[_c('b-form-input',{staticClass:"w-auto",attrs:{"placeholder":"Search"},on:{"keyup":_vm.keySearch},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('b-button',{attrs:{"variant":"dark"},on:{"click":_vm.viewTrash}},[(!_vm.deleted)?_c('i',{staticClass:"fas fa-trash"}):_c('i',{staticClass:"fas fa-undo"}),_vm._v(" "+_vm._s(_vm.deleted ? 'Back' : 'View Trash')+" ")])],1)]),_c('div',{staticClass:"table-responsive"},[_c('b-table',{staticClass:"custom-table",attrs:{"fields":_vm.fields,"items":_vm.pagination.tableData,"bordered":"","no-local-sorting":"","sort-by":_vm.sort.filed,"sort-desc":_vm.sort.desc},on:{"update:sortBy":function($event){return _vm.$set(_vm.sort, "filed", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "filed", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "desc", $event)}},scopedSlots:_vm._u([{key:"cell(stage)",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.stage.replace('Stock', 'Drivability').replace('.1', '+')))]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.status?'success':'danger'}},[_vm._v(" "+_vm._s(item.status ? 'Enabled' : 'Disabled')+" ")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localeDate")(item.created_at))+" ")]}},{key:"cell(is_hpp)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_hpp ? 'Yes' : 'No')+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button-group',[_c('router-link',{staticClass:"py-1",attrs:{"to":{name: 'view-tuned-file', params: {id: item._id}}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{attrs:{"role":"link","variant":"dark","size":"sm"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}},[_c('i',{staticClass:"fas fa-eye"})])]}}],null,true)}),_c('b-button',{staticClass:"py-1",attrs:{"disabled":item.deleted_at,"variant":[item.is_supplying? 'success':'danger']},on:{"click":function($event){return _vm.changeStatus(item)}}},[_c('i',{staticClass:"fas",class:[item.is_supplying? 'fa-check':'fa-times']})]),(!item.deleted_at)?_c('b-button',{staticClass:"py-1",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.trashFile(item)}}},[_c('i',{staticClass:"fas fa-trash"})]):_c('b-button',{staticClass:"py-1",attrs:{"variant":"success","size":"sm","title":"Restore file"},on:{"click":function($event){return _vm.recoverFile(item)}}},[_c('i',{staticClass:"fas fa-trash-restore"})]),_c('b-button',{staticClass:"py-1",attrs:{"variant":"danger","size":"sm","title":"Delete file permanently"},on:{"click":function($event){return _vm.deleteFile(item)}}},[_c('i',{staticClass:"fas fa-eraser"})])],1)]}}])})],1),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.pagination.total,"per-page":_vm.pagination.perPage,"align":"end"},on:{"change":_vm.paginate},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }